import {
  keyBy,
  groupBy,
  chunk,
  flatten,
  cloneDeep,
  defaults,
  get,
  set,
} from 'lodash';
import pluralize from 'pluralize';

export async function loadElementWithConnections(params) {
  const [element, connections] = await Promise.all([
    _getElement(params),
    _getConnectedElements(params),
  ]);
  return {
    ...element,
    connections,
  };
}

async function _getElement({ where, exaptiveClient }) {
  const elementResponse = await exaptiveClient.element.read({
    data: { where },
    params: { cityNamespace: 'main' },
  });
  return elementResponse.data;
}

async function _getConnectedElements({
  where,
  exaptiveClient,
  loadTransitiveConnections = true,
}) {
  const response = await exaptiveClient.element.getConnectedElements({
    data: { where },
    params: { cityNamespace: 'main' },
  });

  if (!loadTransitiveConnections) {
    return response.data.map(connection => ({
      element: connection.element,
      connection: connection.connection,
      direction: connection.isSrc ? 'out' : 'in',
    }));
  }
  const connections = await Promise.all(
    response.data.map(async connection => {
      if (!connection.element.isPlaceholder) {
        const connected = await exaptiveClient.element.getConnectedElements({
          data: { where: { uuid: connection.element.uuid } },
          params: { cityNamespace: 'main' },
        });
        return {
          element: { ...connection.element, connections: connected.data },
          connection: connection.connection,
          direction: connection.isSrc ? 'out' : 'in',
        };
      }
      return {
        element: connection.element,
        connection: connection.connection,
        direction: connection.isSrc ? 'out' : 'in',
      };
    })
  );

  return connections.filter(Boolean);
}

export async function loadElementType(params) {
  const { exaptiveClient, where } = params;
  const elementTypeResponse = await exaptiveClient.elementType.read({
    data: { where },
    params: { cityNamespace: 'main' },
  });

  await loadElementTypeConfigsMany({
    exaptiveClient,
    elementTypes: [elementTypeResponse.data],
  });
  return elementTypeResponse.data;
}

export function getElementTypePluralName(elementType) {
  return (
    elementType?.configs?.appearance?.pluralName ||
    pluralize(elementType?.name || '')
  );
}

export const CONFIGS_FIELD = 'configs';
export const CONFIGS_RAW_FIELD = '_raw';

export async function loadElementTypeConfigsMany(params) {
  const { exaptiveClient, elementTypes } = params;
  await _loadElementTypeConfigsMany(exaptiveClient, elementTypes);
  return elementTypes;
}

async function _loadElementTypeConfigsMany(exaptiveClient, elementTypes) {
  const configs = await loadManyResourceConfigs({
    exaptiveClient,
    resourceType: 'elementType',
    types: ['schemaBuilder', 'graphForm', 'legacyForm'],
    resources: elementTypes,
  });

  if (configs) {
    await Promise.all(
      configs.map(async c => {
        await _initOneElementType({
          elementType: c.resource,
          schemaBuilderConfig: c.configs.schemaBuilder,
          legacyFormConfig: c.configs.legacyForm,
          graphForm: c.configs.graphForm,
        });
      })
    );
  }
}

export async function loadElementTypeConfigs(params) {
  const { exaptiveClient, elementType } = params;
  await _loadElementTypeConfigsMany(exaptiveClient, [elementType]);
  return elementType;
}

export async function loadManyResourceConfigs({
  exaptiveClient,
  resourceType,
  types,
  resources,
}) {
  // IMPORTANT: load in chunks.
  // URLs have a max length limit depending on browser.
  // Should keep query params under 1000 chars to be safe.
  // i.e. chunk size < 1000 / 36      36=length of resource ID (UUID)
  const chunks = chunk(resources, 20);
  const res = await Promise.all(
    chunks.map(c =>
      _loadManyConfigs({
        exaptiveClient,
        resourceType,
        types,
        resources: c,
      })
    )
  );
  return flatten(res);
}

async function _loadManyConfigs({
  exaptiveClient,
  resourceType,
  types,
  resources,
}) {
  const configs = (
    await exaptiveClient.app.resourceConfig.list({
      resourceType,
      type: types.join(','),
      resourceId: resources.map(i => i.uuid).join(','),
      params: {
        cityNamespace: 'main',
        autoCreateNew: 'true',
      },
    })
  ).data;
  const resLookup = keyBy(resources, x => x.uuid);
  const groups = groupBy(configs, c => c.resourceId);
  return Object.values(groups).map(list => ({
    resource: resLookup[list[0].resourceId],
    configs: keyBy(list, c => c.type),
  }));
}

function _initOneElementType({
  elementType,
  schemaBuilderConfig,
  legacyFormConfig,
  graphForm,
}) {
  defaults(elementType, {
    [CONFIGS_FIELD]: {
      [CONFIGS_RAW_FIELD]: {},
    },
  });
  const configsStore = elementType?.[CONFIGS_FIELD] || {};
  function _addConfigProp(dstPath, config, srcPath) {
    const _value = cloneDeep(get(config, srcPath));
    set(configsStore, dstPath, _value);
  }
  [schemaBuilderConfig, legacyFormConfig, graphForm].forEach(config => {
    if (!config) {
      return;
    }

    configsStore[CONFIGS_RAW_FIELD][config.type] = config;

    switch (config.type) {
      case 'schemaBuilder': {
        _addConfigProp('position', config, 'data.position');
        _addConfigProp('appearance', config, 'data.appearance');
        _addConfigProp('integrations', config, 'data.integrations');
        break;
      }
      case 'legacyForm': {
        _addConfigProp('connections', config, 'data.connections');
        _addConfigProp('properties', config, 'data.properties');
        _addConfigProp('legacyAppearance', config, 'data.appearance');
        _addConfigProp('_profile', config, 'data._profile');
        _addConfigProp('_viewer', config, 'data._viewer');
        break;
      }
      case 'graphForm': {
        _addConfigProp('fields', config, 'data.fields');
        _addConfigProp('graphForm', config, 'data.graphForm');
        break;
      }
      default: {
        // nothing
      }
    }
  });
}

export async function waitForElementServiceJob({ jobId, exaptiveClient }) {
  return waitForValue(async () => {
    const res = await exaptiveClient.operation.getElementServiceJobStatus({
      jobId,
      params: { cityNamespace: 'main' },
    });
    if (res.data.status !== 'complete') {
      throw new Error(`Not ready ${res.data.status}`);
    }
    return res;
  });
}

// waits for a return value
// i.e. waits until callback no longer errs.
// Use when we need the result value of the test call
async function waitForValue(
  callback,
  { timeout = 30 * 1000, interval = 500 } = {}
) {
  if (!(timeout > 0)) throw new Error('Invalid timeout');
  if (!(interval > 0)) throw new Error('Invalid interval');
  const baseStack = new Error().stack;
  return new Promise((resolve, reject) => {
    let waitDone = false;
    let lastError;
    const timeoutHandle = setTimeout(() => {
      clear();
      let message = `wait timeout  ${timeout}ms at\n${baseStack}\n`;
      if (lastError) message = `${message}\nlast error=${lastError.stack}`;
      reject(new Error(message));
    }, timeout);

    let intervalHandle;
    startInterval();

    function startInterval() {
      if (intervalHandle) clearTimeout(intervalHandle);

      // case: wait ended already
      // This may happen if the wait timed out and rejected while the previous interval was still
      // running.
      // Don't start a new interval
      if (waitDone) return;

      intervalHandle = setTimeout(async () => {
        let result = null;
        try {
          result = await callback();
        } catch (e) {
          lastError = e;
          startInterval();
          return;
        }

        clear();
        resolve(result);
      }, interval);
    }

    function clear() {
      waitDone = true;
      clearTimeout(timeoutHandle);
      clearInterval(intervalHandle);
    }
  });
}
